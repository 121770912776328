.breadcrumbs {
	padding: 36px 0;
	background: $color-brand no-repeat center center / cover;
	@include image-set(
		"#{$imagePath}bg-breadcrumbs.jpg",
		url("#{$imagePath}bg-breadcrumbs.avif") type("image/avif"),
		url("#{$imagePath}bg-breadcrumbs.webp") type("image/webp"),
		url("#{$imagePath}bg-breadcrumbs.jpg") type("image/jpeg")
	);
}
.breadcrumb-nav {
	&__list {
		margin: 0;
		padding: 0;
		display: inline-flex;
		flex-wrap: wrap;
		list-style-type: none;
		color: white;
		font-size: rem(15px);
	}

	&__item {
		text-transform: uppercase;
		font-weight: bold;

		&:not(:last-child)::after {
			content: "\>";
			margin: 0 1rem;
			color: $color-secondary;
		}
	}

	&__link {
		text-decoration: underline;
		display: inline-block;

		@include link(&) {
			color: inherit;
		}

		&[aria-current] {
			pointer-events: none;
			text-decoration: none;
			font-weight: bold;
		}
	}
}
