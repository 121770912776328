
.sitemap {
	&__list {
		margin: 0 0 3em;
		padding: 0;
		list-style-type: none;
		font-size: rem(18px);

		.sitemap__list {
			font-size: 1rem;
			padding-left: 1.5em;
			margin-top: .5em;
			margin-bottom: 0;
		}
	}

	&__item {
		margin: 0 0 .5em;
	}

	&__link {
		@include link(&) {
			text-decoration: underline;
		}

		@include link-over(&) {
			text-decoration: none;
		}
	}
}
