@charset "UTF-8";
/*
* BASE DEPENDENCIES
*/
/*****************************************
* Blackwell - MIXINS
*****************************************/
/*****************************************
* blackwell - FUNCTIONS
*****************************************/
/*==============================
	FONTS
==============================*/
/*==============================
	COLORS
==============================*/
/*==============================
	SIZES
==============================*/
/* COMPONENT VARIABLES */
/*==============================
	FORMS
==============================*/
/*==============================
	BUTTONS
==============================*/
/*
* LAYOUT
*/
.breadcrumbs {
  padding: 36px 0;
  background: #1d2337 no-repeat center center/cover;
  background-image: url("/assets/images/bg-breadcrumbs.jpg");
  background-image: image-set(url("/assets/images/bg-breadcrumbs.avif") type("image/avif"), url("/assets/images/bg-breadcrumbs.webp") type("image/webp"), url("/assets/images/bg-breadcrumbs.jpg") type("image/jpeg"));
}

.breadcrumb-nav__list {
  margin: 0;
  padding: 0;
  display: inline-flex;
  flex-wrap: wrap;
  list-style-type: none;
  color: white;
  font-size: 0.9375rem;
}
.breadcrumb-nav__item {
  text-transform: uppercase;
  font-weight: bold;
}
.breadcrumb-nav__item:not(:last-child)::after {
  content: ">";
  margin: 0 1rem;
  color: #84c450;
}
.breadcrumb-nav__link {
  text-decoration: underline;
  display: inline-block;
}
.breadcrumb-nav__link, .breadcrumb-nav__link:link, .breadcrumb-nav__link:visited {
  color: inherit;
}
.breadcrumb-nav__link[aria-current] {
  pointer-events: none;
  text-decoration: none;
  font-weight: bold;
}

.sitemap__list {
  margin: 0 0 3em;
  padding: 0;
  list-style-type: none;
  font-size: 1.125rem;
}
.sitemap__list .sitemap__list {
  font-size: 1rem;
  padding-left: 1.5em;
  margin-top: 0.5em;
  margin-bottom: 0;
}
.sitemap__item {
  margin: 0 0 0.5em;
}
.sitemap__link, .sitemap__link:link, .sitemap__link:visited {
  text-decoration: underline;
}
.sitemap__link:hover, .sitemap__link:focus, .sitemap__link:active {
  text-decoration: none;
}

.login-links {
  margin: 0 0 2rem;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.login-links__item {
  margin: 5px 15px;
}
.page {
  padding-top: 5.25rem;
  padding-bottom: 5.25rem;
}
.page.services-page {
  padding-top: 0;
  padding-bottom: 0;
}

.page-module {
  margin-bottom: 3em;
}

.common-page {
  padding-top: 75px;
  padding-bottom: 75px;
}

.product-categories {
  display: grid;
  grid-gap: 25px;
  gap: 25px;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
}
.product-categories__item {
  background-color: #1d2337;
  display: flex;
  flex-direction: column;
  position: relative;
  color: white;
  overflow: hidden;
}
.product-categories__image {
  aspect-ratio: 3/4.2;
  object-fit: cover;
  object-position: center;
  transform: scale(1);
  transition: transform 0.15s ease-out;
}
.product-categories__item.product-categories__item--no-image::after {
  content: "";
  display: block;
  aspect-ratio: 3/4.2;
  object-fit: cover;
  object-position: center;
  transform: scale(1);
  transition: transform 0.15s ease-out;
}
.product-categories__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  background: linear-gradient(to top, rgba(13, 19, 34, 0.9), rgba(13, 19, 34, 0) 50%);
}
.product-categories__link, .product-categories__link:link, .product-categories__link:visited {
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.25;
  color: inherit;
  text-decoration: none;
  padding: 23px 25px;
}
.product-categories__link:hover + .product-categories__image, .product-categories__link:focus + .product-categories__image, .product-categories__link:active + .product-categories__image {
  transform: scale(1.2);
}

.testimonials {
  background-color: #e2e3e4;
  padding: 2.1875rem 1.875rem 1.875rem;
  margin-bottom: 5rem;
  position: relative;
}
@media screen and (min-width: 34.375rem) {
  .testimonials {
    padding: 3.75rem clamp(5.625rem, 10vw, 12.5rem) 2.1875rem;
  }
}
.testimonials__wrapper {
  overflow: auto;
}
.testimonials__wrapper[data-infinity-initialised] {
  overflow: hidden;
}
@media screen and (min-width: 0.0625rem) and (max-width: 34.3125rem) {
  .testimonials__nav {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 30px 0 0 0;
  }
}
@media screen and (min-width: 34.375rem) {
  .testimonials .infinity-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .testimonials .infinity-button--previous {
    left: clamp(1.25rem, 3vw, 3.75rem);
  }
  .testimonials .infinity-button--next {
    right: clamp(1.25rem, 3vw, 3.75rem);
  }
}
.testimonials__list {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.testimonials__item {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
}
.testimonials__quote {
  margin: 0;
}
.testimonials__quote p {
  margin: 0 0 1em;
  font-size: 1.25rem;
  color: #1d2337;
}
.testimonials__footer {
  font-size: 0.75rem;
  font-weight: 800;
}

.faq__heading {
  font-weight: 800;
  font-size: 1.875rem;
  color: #1d2337;
  text-align: center;
  margin: 0 0 22px;
  padding: 0 0 0.5em;
  border-bottom: 1px #84c450 solid;
}
.faq__heading:not(:first-child) {
  margin-top: 3.25rem;
}
.faq__details {
  background-color: #e1e3e3;
  padding: clamp(1.5rem, 5vw, 3.25rem) 25px;
  text-align: center;
  margin: 0 0 20px;
  position: relative;
}
.faq__question, .faq__answer {
  max-width: 70ch;
  margin: 0 auto;
}
.faq__question {
  cursor: pointer;
  font-size: 1.25rem;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
}
.faq__question::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.faq__question::after {
  content: "";
  width: 15px;
  height: 15px;
  margin: 0 0 0 42px;
  display: inline-block;
  transform: rotate(0deg);
  transition: transform 0.12s ease-out;
  background: transparent url("/assets/images/arrow-down.svg") no-repeat 50% 50%;
}
.faq__details[open] .faq__question::after {
  transform: rotate(180deg);
  transform-origin: center;
}
.faq__answer {
  font-size: 0.875rem;
  font-weight: bold;
  margin-top: 1.25rem;
  position: relative;
  z-index: 5;
}
@media screen and (min-width: 50rem) {
  .faq__answer {
    margin-top: 1.875rem;
  }
}

.products__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18.75rem, 1fr));
  grid-gap: 40px;
  gap: 40px;
}
@media screen and (min-width: 75rem) {
  .products__list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.product {
  border: 1px #84c450 solid;
  padding: clamp(1.875rem, 2vw, 3.125rem);
  display: flex;
  flex-direction: column;
  font-size: 0.9375rem;
}
.product__name {
  text-transform: uppercase;
  font-weight: bold;
  color: #1d2337;
  font-size: 1.125rem;
  margin: 0 0 1.875rem;
}
.product__image {
  max-width: 200px;
  height: 200px;
  object-fit: contain;
  object-position: center;
  margin-bottom: 30px;
}
.product .text-module {
  font-size: 0.9375rem;
}
.product .text-module p {
  font-size: inherit;
}
.product .text-module ul {
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
}
.product .text-module ul li::before {
  content: "−";
  margin-right: 0.35em;
}
.product .text-module p + ul {
  margin-top: 0;
}
.product__attachment {
  margin: 0;
}

@media screen and (min-width: 0.0625rem) and (max-width: 37.4375rem) {
  .tabs {
    overflow-x: auto;
    width: 100%;
    position: relative;
  }
}

.product-tabs {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 0.0625rem) and (max-width: 37.4375rem) {
  .product-tabs {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
}
.product-tabs__tab {
  color: #1d2337;
  border: 1px #1d2337 solid;
  background-color: white;
  transition: color 0.1s ease-out, background-color 0.1s ease-out;
  margin: 7px;
  padding: 12px 20px 14px;
  font-weight: bold;
  font-size: 0.875rem;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
}
@media screen and (min-width: 0.0625rem) and (max-width: 37.4375rem) {
  .product-tabs__tab {
    flex-shrink: 0;
  }
}
@media screen and (min-width: 87.5rem) {
  .product-tabs__tab {
    font-size: 0.9375rem;
    padding: 19px 50px 21px;
  }
}
.product-tabs__tab[aria-selected=true] {
  background-color: #1d2337;
  color: white;
  cursor: default;
}
.product-tabs__link, .product-tabs__link:link, .product-tabs__link:visited {
  color: inherit;
  text-decoration: none;
}

.tab-panes {
  display: flex;
  flex-direction: column;
}
.tab-panes [role=tabpanel] {
  display: block;
  opacity: 1;
  transform: translateX(0);
  position: static;
  height: 0;
  overflow: hidden;
}
.tab-panes [role=tabpanel][aria-hidden=false] {
  order: -1;
  height: auto;
}
.tab-panes [role=tabpanel][aria-hidden=true] {
  pointer-events: none;
  position: absolute;
  opacity: 0;
  transform: translateX(-10rem);
}
.tab-panes.user-action [role=tabpanel] {
  transition: opacity 0.25s ease-out, transform 0.4s ease-out;
}

.service-page {
  background: transparent url("/assets/images/bg-tech.svg") no-repeat 0 50%;
  background-blend-mode: color-burn;
}
.service-page__content {
  display: grid;
  gap: 60px;
}
@media screen and (min-width: 50rem) {
  .service-page__content {
    grid-template-columns: 1fr 420px;
  }
}
.service-page svg {
  width: 106px;
  height: 106px;
  display: none;
}
@media screen and (min-width: 50rem) {
  .service-page svg {
    display: inline-block;
  }
}
.service-page svg path {
  fill: #84c450;
}
.service-page svg path.accent {
  fill: #0d1322;
}
.service-page__flair {
  position: relative;
}
.service-page__flair::after {
  content: "";
  display: block;
  height: 30px;
  background: #84c450 linear-gradient(to right, #84c450, #1b5962);
}
.service-page__stats {
  background-color: #e1e3e3;
  background-color: #e1e3e3;
  padding: 50px 40px 40px 60px;
  position: relative;
}
@media screen and (min-width: 87.5rem) {
  .service-page__stats {
    padding-top: 80px;
    padding-left: 150px;
  }
}
.service-page__stats p {
  margin: 0;
}
.service-page__stats p:not(:last-child) {
  margin-bottom: clamp(30px, 5vw, 75px);
}
.service-page__stats p strong {
  font-size: clamp(1.875rem, 4vw, 3.125rem);
  font-weight: 800;
  color: #1d2337;
  display: block;
  line-height: 1;
  margin-bottom: 10px;
}
@media screen and (min-width: 50rem) {
  .service-page .page__header {
    display: flex;
    align-items: center;
  }
  .service-page .main-heading {
    margin-bottom: 0;
    margin-right: clamp(30px, 5vw, 75px);
  }
}