
.login-links {
	margin: 0 0 2rem;
	padding: 0;
	list-style-type: none;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;

	&__item {
		margin: 5px 15px;
	}

	&__link {

	}
}
