@use "sass:math";

/*
* BASE DEPENDENCIES
*/

@import "abstracts/mixins", "abstracts/functions", "abstracts/vars";

/*
* LAYOUT
*/

@import "components/breadcrumb-nav", "components/sitemap", "components/lists";

.page {
	padding-top: rem(84px);
	padding-bottom: rem(84px);

	&.services-page {
		padding-top: 0;
		padding-bottom: 0;
	}
}

.page-module {
	margin-bottom: 3em;
}

.common-page {
	padding-top: 75px;
	padding-bottom: 75px;
}

.product-categories {
	display: grid;
	grid-gap: 25px;
	gap: 25px;
	grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));

	&__item {
		background-color: $color-brand;
		display: flex;
		flex-direction: column;
		position: relative;
		color: white;
		overflow: hidden;
	}
	&__image {
		aspect-ratio: 3 / 4.2;
		object-fit: cover;
		object-position: center;
		transform: scale(1);
		transition: transform 0.15s ease-out;
	}
	&__item.product-categories__item--no-image::after {
		content: "";
		display: block;
		aspect-ratio: 3 / 4.2;
		object-fit: cover;
		object-position: center;
		transform: scale(1);
		transition: transform 0.15s ease-out;
	}
	&__link {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 3;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-start;
		background: linear-gradient(
			to top,
			rgba($color-brand-darker, 0.9),
			rgba($color-brand-darker, 0) 50%
		);

		@include link(&) {
			font-size: rem(20px);
			font-weight: bold;
			line-height: math.div(25, 20);
			color: inherit;
			text-decoration: none;
			padding: 23px 25px;
		}
		@include link-over(&) {
			+ .product-categories__image {
				transform: scale(1.2);
			}
		}
	}
}

.testimonials {
	background-color: #e2e3e4;
	padding: rem(35px) rem(30px) rem(30px);
	margin-bottom: rem(80px);
	position: relative;

	@include min(550px) {
		padding: rem(60px) clamp(rem(90px), 10vw, rem(200px)) rem(35px);
	}

	&__wrapper {
		overflow: auto;

		&[data-infinity-initialised] {
			overflow: hidden;
		}
	}

	@include minmax(1px, 549px) {
		&__nav {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 20px;
			margin: 30px 0 0 0;
		}
	}

	@include min(550px) {
		.infinity-button {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);

			&--previous {
				left: clamp(rem(20px), 3vw, rem(60px));
			}
			&--next {
				right: clamp(rem(20px), 3vw, rem(60px));
			}
		}
	}

	&__list {
		display: flex;
		align-items: center;
		margin: 0;
		padding: 0;
		list-style-type: none;
	}
	&__item {
		flex-shrink: 0;
		display: flex;
		flex-direction: column;
		width: 100%;
		text-align: center;
	}
	&__quote {
		margin: 0;
		p {
			margin: 0 0 1em;
			font-size: rem(20px);
			color: $color-brand;
		}
	}
	&__footer {
		font-size: rem(12px);
		font-weight: 800;
	}
}

.faq {
	&__heading {
		font-weight: 800;
		font-size: rem(30px);
		color: $color-brand;
		text-align: center;
		margin: 0 0 22px;
		padding: 0 0 0.5em;
		border-bottom: 1px $color-secondary solid;

		&:not(:first-child) {
			margin-top: rem(52px);
		}
	}
	&__details {
		background-color: #e1e3e3;
		padding: clamp(rem(24px), 5vw, rem(52px)) 25px;
		text-align: center;
		margin: 0 0 20px;
		position: relative;
	}
	&__question,
	&__answer {
		max-width: 70ch;
		margin: 0 auto;
	}
	&__question {
		cursor: pointer;
		font-size: rem(20px);
		font-weight: normal;
		display: flex;
		align-items: center;
		justify-content: center;

		&::before {
			content: "";
			@include fill;
		}

		&::after {
			content: "";
			width: 15px;
			height: 15px;
			margin: 0 0 0 42px;
			display: inline-block;
			transform: rotate(0deg);
			transition: transform 0.12s ease-out;
			background: transparent url("/assets/images/arrow-down.svg") no-repeat
				50% 50%;
		}
	}
	&__details[open] .faq__question::after {
		transform: rotate(180deg);
		transform-origin: center;
	}
	&__answer {
		font-size: rem(14px);
		font-weight: bold;
		margin-top: rem(20px);
		position: relative;
		z-index: 5;

		@include min(800px) {
			margin-top: rem(30px);
		}
	}
}

.products {
	&__list {
		list-style-type: none;
		margin: 0;
		padding: 0;

		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(rem(300px), 1fr));
		grid-gap: 40px;
		gap: 40px;

		@include min(1200px) {
			grid-template-columns: 1fr 1fr 1fr;
		}
	}

	&__item {
	}
}

.product {
	border: 1px $color-secondary solid;
	padding: clamp(rem(30px), 2vw, rem(50px));
	display: flex;
	flex-direction: column;
	font-size: rem(15px);

	&__name {
		text-transform: uppercase;
		font-weight: bold;
		color: $color-brand;
		font-size: rem(18px);
		margin: 0 0 rem(30px);
	}

	&__image {
		max-width: 200px;
		height: 200px;
		object-fit: contain;
		object-position: center;
		margin-bottom: 30px;
	}

	.text-module {
		font-size: rem(15px);

		p {
			font-size: inherit;
		}

		ul {
			list-style-type: none;
			padding: 0;
			margin: 20px 0;

			li {
				&::before {
					content: "\2212";
					margin-right: 0.35em;
				}
			}
		}

		p + ul {
			margin-top: 0;
		}
	}

	&__attachment {
		margin: 0;
	}
}

.tabs {
	@include minmax(1px, 599px) {
		overflow-x: auto;
		width: 100%;
		position: relative;
	}
}

.product-tabs {
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;

	@include minmax(1px, 599px) {
		flex-wrap: nowrap;
		justify-content: flex-start;
	}

	&__tab {
		color: $color-brand;
		border: 1px $color-brand solid;
		background-color: white;
		transition: color 0.1s ease-out, background-color 0.1s ease-out;
		margin: 7px;
		padding: 12px 20px 14px;
		font-weight: bold;
		font-size: rem(14px);
		text-transform: uppercase;
		text-align: center;
		cursor: pointer;

		@include minmax(1px, 599px) {
			flex-shrink: 0;
		}

		@include min(1400px) {
			font-size: rem(15px);
			padding: 19px 50px 21px;
		}

		&[aria-selected="true"] {
			background-color: $color-brand;
			color: white;
			cursor: default;
		}
	}

	&__link {
		@include link(&) {
			color: inherit;
			text-decoration: none;
		}
	}
}

.tab-panes {
	display: flex;
	flex-direction: column;

	[role="tabpanel"] {
		display: block;
		opacity: 1;
		transform: translateX(0);
		position: static;
		height: 0;
		overflow: hidden;

		&[aria-hidden="false"] {
			order: -1;
			height: auto;
		}

		&[aria-hidden="true"] {
			pointer-events: none;
			position: absolute;
			opacity: 0;
			transform: translateX(-10rem);
		}
	}

	&.user-action {
		[role="tabpanel"] {
			transition: opacity 0.25s ease-out, transform 0.4s ease-out;
		}
	}
}

.service-page {
	background: transparent url("/assets/images/bg-tech.svg") no-repeat 0 50%;
	background-blend-mode: color-burn;

	&__content {
		display: grid;
		gap: 60px;

		@include min(800px) {
			grid-template-columns: 1fr 420px;
		}
	}

	svg {
		width: 106px;
		height: 106px;
		display: none;

		@include min(800px) {
			display: inline-block;
		}

		path {
			fill: $color-secondary;

			&.accent {
				fill: $color-brand-darker;
			}
		}
	}

	&__flair {
		position: relative;
		//@include pad(#e1e3e3, right);

		&::after {
			content: "";
			display: block;
			height: 30px;
			background: $color-secondary $gradient-secondary;
		}
	}

	&__stats {
		background-color: #e1e3e3;
		background-color: $color-neutral;
		padding: 50px 40px 40px 60px;
		position: relative;

		@include min(1400px) {
			padding-top: 80px;
			padding-left: 150px;
		}

		p {
			margin: 0;

			&:not(:last-child) {
				margin-bottom: clamp(30px, 5vw, 75px);
			}

			strong {
				font-size: clamp(rem(30px), 4vw, rem(50px));
				font-weight: 800;
				color: $color-brand;
				display: block;
				line-height: 1;
				margin-bottom: 10px;
			}
		}
	}

	@include min(800px) {
		.page__header {
			display: flex;
			align-items: center;
		}

		.main-heading {
			margin-bottom: 0;
			margin-right: clamp(30px, 5vw, 75px);
		}
	}
}
